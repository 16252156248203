import React, { Fragment, useCallback, useState } from "react";
import SimplifiedHowItWorks from "./SimplifiedHowItWorks";
import SmallHeader from "./SmallHeader";
import classes from "./SimplifiedHowItWorksPage.module.css";
import SimplifiedSiteSignupBox from "./SimplifiedSiteSignupBox";
import SimplifiedMobileContainer from "./SimplifiedMobileSite/SimplifiedMobileContainer";
import TopSection from "../components/TopSection";
import LogoAndHeadline from "../components/LogoAndHeadline";
import Signup from "../components/Signup";
import DesktopHowItWorks from "./SimplifiedDesktopSite/DesktopHowItWorks";
import SimplifiedSignup from "./SimplifiedSignup";

const SimplifiedHowItWorksPage: React.FC<{
  isEmailInputFocused?: boolean;
  setIsEmailInputFocused?: (newState: boolean) => void;
}> = ({ isEmailInputFocused, setIsEmailInputFocused }) => {
  // eslint-disable-next-line no-restricted-globals
  const screenWidth: number = screen.width;

  let variableContent = <Fragment></Fragment>;
  if (screenWidth < 600) {
    variableContent = (
      <Fragment>
        <SimplifiedMobileContainer
          isEmailInputFocused={isEmailInputFocused}
          setIsEmailInputFocused={setIsEmailInputFocused}
        />
      </Fragment>
    );
  } else {
    variableContent = (
      <Fragment>
        <TopSection>
          <LogoAndHeadline></LogoAndHeadline>
          {/* TODO -- change this to use the jessie approach */}
          {/* TODO -- change this to the new signup box */}
          <SimplifiedSignup
            isEmailInputFocused={isEmailInputFocused}
            setIsEmailInputFocused={setIsEmailInputFocused}
          />
        </TopSection>
        <DesktopHowItWorks></DesktopHowItWorks>
      </Fragment>
    );
  }

  return variableContent;
};
export default SimplifiedHowItWorksPage;

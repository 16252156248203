import { Fragment } from "react";

import classes from "./Header.module.css";

import HLogo from "../Resources/h-logo.svg";
import { Link, NavLink } from "react-router-dom";
import Props from "../models/Props";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLessThanEqual } from "@fortawesome/free-solid-svg-icons";

const Header: React.FC<{
  onClickJoin: () => void;
  onMenuClick: () => void;
}> = ({ onClickJoin, onMenuClick }) => {
  const navigate = useNavigate();

  const joinHandler = () => {
    onClickJoin();
    navigate("/", { replace: false });
  };

  return (
    <header className={classes.header}>
      <Link to="/">
        <img src={HLogo} className={classes["main-image"]} alt="header"></img>
      </Link>
      <div className={classes.smallSiteNav}>
        <FontAwesomeIcon
          icon={faBars}
          onClick={(event) => onMenuClick()}
          className="fa-2xl"
        />
      </div>
      <div className={classes.largeSiteNav}>
        <NavLink
          to="faq"
          className={({ isActive }) =>
            isActive
              ? `${classes.activeLink} ${classes.navLink}`
              : classes.navLink
          }
        >
          FAQ
        </NavLink>
        <NavLink
          onClick={(event) => {
            event.preventDefault();
            joinHandler();
          }}
          to="/"
          className={({ isActive }) =>
            isActive
              ? `${classes.activeLink} ${classes.navLink}`
              : classes.navLink
          }
        >
          Join
        </NavLink>
        {/* <p onClick={(event) => navigate("/faq", { replace: false })}>FAQ</p> */}
        {/* <p onClick={(event) => navigate("/buyer", { replace: false })}>
            Homebuyers
          </p>
          <p onClick={(event) => navigate("/investor", { replace: false })}>
            Investors
          </p>
          <p onClick={(event) => navigate("/realtor", { replace: false })}>
            Realtors
          </p> */}
        {/* <p onClick={(event) => navigate("/about", { replace: false })}>
            How it works
          </p> */}
      </div>
    </header>
  );
};

export default Header;

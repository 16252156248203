import { Fragment } from "react";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";

import classes from "./BurgerMenu.module.css";
import CrossIcon from "../Resources/x-solid.svg";

const Backdrop = (props) => {
  return <div className={classes.backdrop} onClick={props.onClose} />;
};

const ModalOverlay = (props) => {
  const navigate = useNavigate();

  return (
    <div className={classes.modal}>
      <div className={classes.crossIconContainer} onClick={props.onClose}>
        <img
          src={CrossIcon}
          className={classes["crossIcon"]}
          alt="cross-icon"
        ></img>
      </div>
      <div className={classes.content}>
        <div className={classes.textContent}>
          <p
            onClick={(event) => {
              navigate("/", { replace: false });
              props.onClose();
            }}
          >
            Home
          </p>

          <p
            onClick={(event) => {
              navigate("/faq", { replace: false });
              props.onClose();
            }}
          >
            FAQ
          </p>
          {/* <p
            onClick={(event) => {
              navigate("/buyer", { replace: false });
              props.onClose();
            }}
          >
            Homebuyers
          </p>
          <p
            onClick={(event) => {
              navigate("/investor", { replace: false });
              props.onClose();
            }}
          >
            Investors
          </p>
          <p
            onClick={(event) => {
              navigate("/realtor", { replace: false });
              props.onClose();
            }}
          >
            Realtors
          </p>  */}
          <p
            onClick={(event) => {
              navigate("/", { replace: false });
              props.onClose();
              props.onClickJoin();
            }}
          >
            Join
          </p>
        </div>
        {props.children}
      </div>
    </div>
  );
};

const portalElement = document.getElementById("overlays");

const BurgerMenu = (props) => {
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Backdrop onClose={props.onClose} />,
        portalElement
      )}
      {ReactDOM.createPortal(
        <ModalOverlay onClose={props.onClose} onClickJoin={props.onClickJoin}>
          {props.children}
        </ModalOverlay>,
        portalElement
      )}
    </Fragment>
  );
};

export default BurgerMenu;

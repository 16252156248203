import React from "react";
import classes from "./SimplifiedMobileContainer.module.css";

import MobileHomePageTopSection from "../../mobile/MobileHomePageTopSection";
import SimplifiedMobileHowItWorksSection from "./SimplifiedMobileHowItWorksSection";
import MobileBenefitsSection from "./MobileBenefitsSection";
import SimplifiedSignup from "../SimplifiedSignup";
import HedgeWordLogo from "../../Resources/hedge-word-logo.svg";
import LogoAndHeadline from "../../components/LogoAndHeadline";

const SimplifiedMobileContainer: React.FC<{
  isEmailInputFocused?: boolean;
  setIsEmailInputFocused?: (newState: boolean) => void;
}> = ({ isEmailInputFocused, setIsEmailInputFocused }) => {
  // question -- do we want to keep the hamburger menu at the top?
  //sHeader
  // sMobileTopSection --> can use current mobile top section for this
  //sMobileHowItWorksSection
  // sMobileBenefitsSection

  return (
    <div className={classes.simplifiedMobileContainer}>
      <div className={classes.topSectionContainer}>
        <LogoAndHeadline></LogoAndHeadline>

        {/* <img
          src={HedgeWordLogo}
          className={classes.wordLogo}
          alt="hedge-logo"
        ></img> */}
        <SimplifiedSignup
          isEmailInputFocused={isEmailInputFocused}
          setIsEmailInputFocused={setIsEmailInputFocused}
        />
      </div>
      <SimplifiedMobileHowItWorksSection />
      <MobileBenefitsSection />
    </div>
  );
};
export default SimplifiedMobileContainer;

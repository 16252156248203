import React, { CSSProperties, Fragment, useRef, useEffect } from "react";
import { useState } from "react";
import { sendRequest } from "../utils/send-request";
import classes from "./SimplifiedSignup.module.css";
import Select, { StylesConfig } from "react-select";
import { Helmet } from "react-helmet";
import PlacesAutocomplete from "../UI/PlacesAutocomplete";

const SimplifiedSignup: React.FC<{
  isEmailInputFocused?: boolean;
  setIsEmailInputFocused?: (newState: boolean) => void;
}> = ({ isEmailInputFocused, setIsEmailInputFocused }) => {
  //Form responses
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("123 Main Street");
  const [desiredCashAmt, setDesiredCashAmt] = useState("$10,000");

  const unFocusEmailInput = () => {
    if (setIsEmailInputFocused) {
      setIsEmailInputFocused(false);
    }
  };

  const setAutocompletedAddress = (address: string) => {
    setAddress(address);
  };

  function gtag(s: string, d: any, body?: { send_to: string }) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(arguments);
  }

  function gtag_report_conversion(url: string) {
    var callback = function () {
      if (typeof url != "undefined") {
        window.location.assign(url);
      }
    };
    gtag("event", "conversion", {
      send_to: "AW-11011481905/qh9VCImop4EYELHC14Ip",
    });
    return false;
  }

  const emailSignupRef: React.RefObject<HTMLInputElement> =
    React.useRef<HTMLInputElement>(null);

  // Booleans for controlling flow
  const [showNextQuestions, setShowNextQuestions] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);

  useEffect(() => {
    if (emailSignupRef.current) {
      if (isEmailInputFocused) {
        // NIT: this isn't perfect on mobile because of the text pop up
        emailSignupRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
        emailSignupRef.current.focus();
      } else {
        emailSignupRef.current.blur();
      }
    }
  }, [isEmailInputFocused, setIsEmailInputFocused]);

  // Ref stuff for the Join button in the header
  let initialEmailInputField = (
    <input
      id="email"
      type="text"
      placeholder="your email"
      ref={emailSignupRef}
      onBlur={unFocusEmailInput}
      onChange={(event) => setEmail(event.target.value)}
    ></input>
  );

  //submit email function
  const submitHandler = async (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    console.log(address);
    console.log(desiredCashAmt);

    setShowNextQuestions(false);
    setShowThankYou(true);

    const data = await sendRequest(
      "https://hedge-homes-default-rtdb.firebaseio.com/emails.json",
      "POST",
      {
        email,
        address,
        desiredCashAmt,
        timestamp: Date.now(),
      }
    );
    console.log(data);
    gtag_report_conversion("https://www.hedge.homes/");
  };

  //click next handler
  const nextButtonHandler = async (
    event: React.FormEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    setShowNextQuestions(true);
  };

  type MyOptionType = {
    label: string;
    value: string;
  };

  type isMulti = false;
  let optionColor = "";
  let optionBackgroundColor = "";
  const customStyles: StylesConfig<MyOptionType, isMulti> = {
    control: (styles, state) => {
      // handling width and some other things in the css file
      return {
        ...styles,
        border: "1.75px solid #004945",
        fontFamily: "Arial",
        fontStyle: "normal",
        fontWeight: "400",
        marginBottom: "10px",
        fontSize: "18px",
        color: "#004945",
        maxWidth: "446.61x",
        textAlign: "left",
      };
    },
    option: (styles, state) => {
      optionColor = "#004945";
      optionBackgroundColor = "#ffffff";
      if (state.isSelected) {
        optionColor = "#ffffff";
        optionBackgroundColor = "#00827B";
      }
      return {
        ...styles,
        fontFamily: "Arial",
        fontStyle: "normal",
        fontWeight: "400",
        marginBottom: "10px",
        fontSize: "18px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "left",
        textAlign: "left",
        color: optionColor,
        backgroundColor: optionBackgroundColor,
        WebkitTapHighlightColor: "yellow",
      };
    },
    valueContainer: (styles, state) => {
      return { ...styles, overflowY: "scroll" };
    },
  };

  // What to show after a user has given their email to answer more questions
  let nextQuestionsHTML = (
    <Fragment>
      <label className={classes.submitText}>Tell us more about your home</label>
      <PlacesAutocomplete setAutocompletedAddress={setAutocompletedAddress} />
      {/* <input
        autoFocus
        id="address"
        type="text"
        placeholder="What's your address?"
        onChange={(event) => setAddress(event.target.value)}
        // value={address}
      ></input> */}
      <input
        id="desiredCashAmt"
        type="text"
        placeholder="How much cash do you want?"
        onChange={(event) => setDesiredCashAmt(event.target.value)}
        // value={desiredCashAmt}
      ></input>
      <button onClick={submitHandler}>Submit</button>
    </Fragment>
  );

  // What to show after a user has given their email to answer more questions
  let postSubmitHTML = (
    <Fragment>
      <label className={classes.thankYouText}>
        Thanks for signing up! We'll be in touch shortly.
        <br />
        <br />
        To reach out in the meantime, email hi@hedge.homes
      </label>
    </Fragment>
  );

  return (
    <Fragment>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=AW-11011481905"
        ></script>
      </Helmet>
      <div className={classes.signupBoxContent}>
        <form>
          {!showNextQuestions && !showThankYou && (
            <Fragment>
              <label id="email" className={classes.submitText}>
                Sign up to learn more
              </label>
              {initialEmailInputField}
              <button onClick={nextButtonHandler}>Next</button>
            </Fragment>
          )}
          {showNextQuestions && nextQuestionsHTML}
          {showThankYou && postSubmitHTML}
        </form>
      </div>
    </Fragment>
  );
};

export default SimplifiedSignup;

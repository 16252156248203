export const sendRequest = async (
  url: string,
  method?: string,
  body?: Object
) => {
  const response = await fetch(url, {
    method: method ?? "GET",
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (!response.ok) {
    throw new Error("Request failed!");
  }

  const data = await response.json();

  return data;
};

import React, { Fragment, useCallback, useRef, useState } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import SimplifiedHowItWorksPage from "./SimplifiedSiteComponents/SimplifiedHowItWorksPage";
import BurgerMenu from "./UI/BurgerMenu";
import FAQPage from "./pages/FAQPage";

function App() {
  const [isEmailInputFocused, setIsEmailInputFocused] = useState(false);
  const [isBurgerMenuShowing, setIsBurgerMenuShowing] = useState(false);

  const showBurgerMenu = useCallback(() => {
    setIsBurgerMenuShowing(true);
  }, []);

  const hideBurgerMenu = useCallback(() => {
    setIsBurgerMenuShowing(false);
  }, []);

  const onClickJoin = useCallback(() => {
    console.log("scrolling to join");
    setIsEmailInputFocused(true);
  }, []);

  return (
    <Fragment>
      {isBurgerMenuShowing && (
        <BurgerMenu onClose={hideBurgerMenu} onClickJoin={onClickJoin} />
      )}
      <div
        className="App"
        style={
          isBurgerMenuShowing ? { position: "fixed" } : { position: "unset" }
        }
      >
        <Header onClickJoin={onClickJoin} onMenuClick={showBurgerMenu}></Header>
        <Routes>
          <Route
            path="/"
            element={
              <SimplifiedHowItWorksPage
                isEmailInputFocused={isEmailInputFocused}
                setIsEmailInputFocused={setIsEmailInputFocused}
              />
            }
          ></Route>
          <Route path="/faq" element={<FAQPage />}></Route>
          <Route path="/*" element={<Navigate replace to={"/"} />}></Route>
        </Routes>
      </div>
      <Footer onClickJoin={onClickJoin}></Footer>
    </Fragment>
  );
}

export default App;

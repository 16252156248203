import React, { Fragment, useState } from "react";
import classes from "./CollapsibleSection.module.css";

const CollapsibleSection: React.FC<{
  children?: React.ReactNode;
  isOpen?: boolean;
  setIsOpen?: (func: (prevState: boolean) => boolean) => void;
  headerTitle: string;
}> = ({ children, isOpen, setIsOpen, headerTitle }) => {
  const [internalIsOpen, setInternalIsOpen] = useState(false);
  const toggleCollapsedState = () => {
    if (setIsOpen === undefined) {
      setInternalIsOpen((prevState: boolean) => !prevState);
    } else {
      setIsOpen((prevState: boolean) => !prevState);
    }
  };
  const isSectionExpanded = isOpen === undefined ? internalIsOpen : isOpen;

  // eslint-disable-next-line no-restricted-globals
  const screenWidth = screen.width;

  return (
    <div
      className={`${
        isSectionExpanded ? classes.fullSizeSection : classes.shrunkenSection
      } contentSection`}
    >
      {" "}
      <div className={classes.sectionHeader} onClick={toggleCollapsedState}>
        <i
          className={`fa-solid ${
            !isSectionExpanded ? "fa-caret-right" : "fa-caret-down"
          } ${classes.caret}
          ${screenWidth < 600 ? classes.mobile : classes.desktop}`}
        ></i>
        <h2>{headerTitle}</h2>
      </div>
      {isSectionExpanded && children}
    </div>
  );
};
export default CollapsibleSection;

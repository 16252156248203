import React from "react";
import HedgeWordLogo from "../Resources/hedge-word-logo.svg";
import classes from "./LogoAndHeadline.module.css";

const LogoAndHeadline = () => {
  return (
    <div className={classes.logoAndHeadlineDiv}>
      <img
        src={HedgeWordLogo}
        className={classes.wordLogo}
        alt="hedge-logo"
      ></img>
      <p className={classes.headline1}>A secondary market for home equity</p>
      <p className={classes.headline2}>
        Unlock the real estate market without the keys to a house
      </p>
    </div>
  );
};

export default LogoAndHeadline;

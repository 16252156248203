import React, { useState } from "react";
import FAQQuestion from "../components/FAQQuestion";
import CollapsibleSection from "../UI/CollapsibleSection";
import classes from "./FAQPage.module.css";

const FAQPage: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  // are we sure we want different sections?
  const howItWorksQAndA = [
    [
      "Who gets to live in the house?",
      "The homeowner, or person who originally lists the equity on Hedge, has full rights to live in the home.",
    ],
    [
      "When can I sell my home if Hedge buys a piece?",
      "Homeowners can sell their home whenever they want. Hedge creates a secondary market where people can trade shares without the primary homeowner having to sell the full home.",
    ],
    [
      "What if the homeowner sells the house?",
      "If the homeowner sells the house, the shares in the home will be sold at the majority shareholder/homeowner's discretion. The homeowner will need to agree to sell the home for a minimum of 60% of its purchase price. Homeowners can be exempt from the sale minimum should their property be listed for more than three years.",
    ],
    [
      "How does Hedge decide what to pay for a piece of a home?",
      "Hedge has a proprietary process for valuing home equity at the time of purchase. Hedge purchases the equity at a 15% discount, similar to the cost of typical home financing outside of a traditional mortgage.",
    ],
    [
      "Is this a loan?",
      "No! There are no monthly payments and the expectation of Hedge being repaid is solely based on the property’s value. You only pay back Hedge when you sell your home and you will never owe Hedge more than your home is worth - Hedge makes money when you make money.",
    ],
    [
      "What does Hedge do with the equity in my home once they purchase it?",
      "Hedge sells the equity to individuals who believe in the value of your property and want to own a piece.",
    ],
    [
      "How does Hedge make money?",
      "Hedge charges investors a 7% to investors who purchase home equity on our platform.",
    ],
  ];
  const homeExpensesQAndA = [
    [
      "Who pays for home insurance?",
      "The homeowner pays for insurance on the home, and they will likely insure the home for fair market value.",
    ],
    [
      "Who pays for property taxes?",
      "Property taxes are paid in full by the homeowner. When buying a home with a mortgage, even if the owner does not have a majority stake in the house they are still responsible for property taxes. Same logic applies here.",
    ],
    [
      "Who pays for home renovations?",
      "The homeowner is responsible for all home renovations. In fact, raising money for a home renovation is one of the reasons a homeowner would sell equity.",
    ],
    [
      "Who pays for physical maintenance and upkeep?",
      "We assume that a homeowner living in the home will take reasonable steps to maintain and upkeep the property. Investors will have a sense of this form the condition of the house shown in the listing on Hedge.",
    ],
    [
      "Aren't there uninsured damages?",
      "This is a risk banks take when they give mortgages. Hedge only works with insured properties. 85% of U.S. homes are insured.",
    ],
    [
      "What about mortgage related risks?",
      "Homeowners can only sell the equity they own. In the event of a bank foreclosure, the investors may lose their investment. However, the foreclosure rate is 1.6% and there are ways to de-risk these investments through securitization.",
    ],
  ];
  const legalityQAndA = [
    [
      "How does this work?",
      "There are a variety of legal mechanisms that make Hedge work. This includes Hedge being added to the deed of the home and a supplementary contract reaffirming our role as silent. The result is that homeowners can access cash and investors get a piece of the equity in return. The rise of fractional ownership sites like Masterworks, Rally, Vino, and more show the legality of this concept.",
    ],
    [
      "How do investors make sure they are repaid?",
      "Hedge is added to the deed of the home which guarantees that when the home is sold, the investors will receive their fraction of the proceeds. If investors purchase 10% of a home when it is worth $400,000 and the home becomes worth $800,000, the investors will have seen their 10% for from $40,000 to $80,000.",
    ],
  ];

  return (
    <div className={classes.faqPageContainer}>
      <div className={classes.questionSection}>
        <h1>How It Works</h1>
        {howItWorksQAndA.map((qAndA) => (
          <section className={classes.faqQuestionSection}>
            <FAQQuestion question={qAndA[0]} answer={qAndA[1]}></FAQQuestion>
          </section>
        ))}
      </div>
      <div className={classes.questionSection}>
        <h1>Home Expenses</h1>
        {homeExpensesQAndA.map((qAndA) => (
          <section className={classes.faqQuestionSection}>
            <FAQQuestion question={qAndA[0]} answer={qAndA[1]}></FAQQuestion>
          </section>
        ))}
      </div>
      <div className={classes.questionSection}>
        <h1>Legal</h1>
        {legalityQAndA.map((qAndA) => (
          <section className={classes.faqQuestionSection}>
            <FAQQuestion question={qAndA[0]} answer={qAndA[1]}></FAQQuestion>
          </section>
        ))}
      </div>
    </div>
  );
};
export default FAQPage;

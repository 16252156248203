import React from "react";
import classes from "./Footer.module.css";
import TopRightArrow from "../Resources/arrow-top-right.svg";
import { useNavigate } from "react-router-dom";

const Footer: React.FC<{ onClickJoin: () => void }> = ({ onClickJoin }) => {
  const navigate = useNavigate();
  return (
    <div className={classes.footerBox}>
      <p className={classes.rightsReservedText}>
        2022 Hedge Technologies. All rights reserved.
      </p>
      <div
        className={classes.joinLinkAndIcon}
        onClick={(event) => {
          onClickJoin();
          // navigate("/", { replace: false });
        }}
      >
        <p className={classes.getInvolvedLink}>Get involved!</p>
        <img
          src={TopRightArrow}
          className={classes["join-arrow"]}
          alt="join"
        ></img>
      </div>
    </div>
  );
};

export default Footer;

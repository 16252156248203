import React from "react";
import classes from "./DesktopHowItWorks.module.css";
import HedgeBuys5Percent from "../../Resources/HedgeBuys5Percent.svg";
import CashFromHouse from "../../Resources/CashFromHouse.svg";
import YouKeep95Percent from "../../Resources/YouKeep95Percent.svg";
import DesktopHowItWorksContentSection from "./DesktopHowItWorksContentSection";

const DesktopHowItWorks: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const hedgeBuys5PercentImage = (
    <img
      className={classes.hedgeBuys5PercentImage}
      src={HedgeBuys5Percent}
      alt="you sell 5% of your home"
    ></img>
  );

  const cashFromHouseImage = (
    <img
      className={classes.cashFromHouseImage}
      src={CashFromHouse}
      alt="you keep your house"
    ></img>
  );

  const youKeep95PercentImage = (
    <img
      className={classes.youKeep95PercentImage}
      src={YouKeep95Percent}
      alt="you keep your house"
    ></img>
  );

  return (
    <div className={classes.desktopHowItWorksContainer}>
      <DesktopHowItWorksContentSection
        listNumber={1}
        image={hedgeBuys5PercentImage}
        listText={"You sell 5% of the total home equity to Hedge"}
      />
      <DesktopHowItWorksContentSection
        listNumber={2}
        image={cashFromHouseImage}
        listText={"You keep full control over your house"}
      />
      {/* note -- they wouldn't actually receive 95% of the proceeds if they hadn't paid off their mortgage, etc */}
      <DesktopHowItWorksContentSection
        listNumber={3}
        image={youKeep95PercentImage}
        listText={"When you sell, you receive 95% of the sale"}
      />
    </div>
  );
};
export default DesktopHowItWorks;

import React from "react";
import classes from "./MobileBenefitsSection.module.css";
import UnlockedCashHouse from "../../Resources/UnlockedCashHouse.svg";
import GreenCheck from "../../Resources/GreenCheck.svg";

const MobileBenefitsSection: React.FC = () => {
  const unlockedCashHouseImage = (
    <img
      className={classes.unlockedCashHouseImage}
      src={UnlockedCashHouse}
      alt="unlock your home value"
    ></img>
  );

  const greenCheckMark = (
    <img
      className={classes.greenCheckMark}
      src={GreenCheck}
      alt="checkmark"
    ></img>
  );

  return (
    <div className={classes.benefitsSectionContainer}>
      <div className={classes.benefitsSectionHeader}>
        {unlockedCashHouseImage}
        <div className={classes.headerText}>Unlock the value of your home</div>
      </div>
      <div className={classes.benefitsSectionListContainer}>
        <div className={classes.benefitsSectionListItem}>
          {greenCheckMark}
          <div className={classes.benefitText}>No additional debt</div>
        </div>
        <div className={classes.benefitsSectionListItem}>
          {greenCheckMark}
          <div className={classes.benefitText}>No fees</div>
        </div>
        <div className={classes.benefitsSectionListItem}>
          {greenCheckMark}
          <div className={classes.benefitText}>No monthly payments</div>
        </div>
        <div className={classes.benefitsSectionListItem}>
          {greenCheckMark}
          <div className={classes.benefitText}>No mortgage impact</div>
        </div>
      </div>
    </div>
  );
};
export default MobileBenefitsSection;

import React from "react";
import CollapsibleSection from "../UI/CollapsibleSection";
import classes from "./FAQQuestion.module.css";

const FAQQuestion: React.FC<{
  children?: React.ReactNode;
  question: string;
  answer: string;
}> = (props) => {
  return (
    <CollapsibleSection headerTitle={props.question}>
      <div className={classes.faqAnswerContainer}>
        <p>{props.answer}</p>
      </div>
    </CollapsibleSection>
  );
};
export default FAQQuestion;

import React from "react";
import Props from "../models/Props";
import classes from "./TopSection.module.css";

const TopSection: React.FC<Props> = ({ children }) => {
  return (
    <div className={classes["topSection"]}>
      <div className={classes["topContentBox"]}>{children}</div>
    </div>
  );
};

export default TopSection;

import React from "react";
import classes from "./SHowItWorksContentSection.module.css";

const SHowItWorksContentSection: React.FC<{
  listNumber: number;
  image: React.ReactNode;
  listText: string;
}> = ({ listNumber, image, listText }) => {
  return (
    <div className={classes.contentSection}>
      {/* might have issue with the image being styled from here inside the div */}
      <div className={classes.image}>{image}</div>
      <div className={classes.contentRow}>
        <div className={classes.listNumber}>{listNumber}</div>
        <div className={classes.listText}>{listText}</div>
      </div>
    </div>
  );
};
export default SHowItWorksContentSection;
